import {Authority} from '@ecosio/auth';
import {COMPANY_TYPE} from '../constants';
import {useAppSelector} from '@/hooks/index';

const FALLBACK_AUTHS = [Authority.USER];

/**
 * Get the company info of the currently logged-in user.
 *
 * @returns {{userConfigCompanyUuid: string, userConfigCompanyName: string, userConfigCompanyType: string, isPartner: boolean}}
 */
export const useCompany = () => {
  const {userConfigCompanyUuid, userConfigCompanyName, userConfigCompanyType} =
    useAppSelector(state => {
      if (state.config.loading) {
        return {
          userConfigCompanyUuid: undefined,
          userConfigCompanyName: undefined,
          userConfigCompanyType: undefined
        };
      }
      return {
        userConfigCompanyUuid: state.config.userConfig.company.uuid,
        userConfigCompanyName: state.config.userConfig.company.name,
        userConfigCompanyType: state.config.userConfig.company.companyType
      };
    });

  return {
    userConfigCompanyUuid,
    userConfigCompanyName,
    userConfigCompanyType,
    isPartner: userConfigCompanyType === COMPANY_TYPE.PARTNER
  };
};
/**
 * Get the array of authorities of the logged-in user.
 * @returns {Array<string>}
 */
export const useAuthorities = () => {
  // https://gitlab.ecosio.com/code/monitor/-/issues/433
  const authorities = useAppSelector(store => {
    // if we're in the partner relation context, try to find the authorities
    // from the selected relation
    if (store.config.isPartner) {
      const relations = store.config.userConfig.partnerRelations || [];
      const selectedCompany = store.config.userConfig.selectedSubCompany;

      const relation = relations.find(r => r.customer.uuid === selectedCompany);

      if (!relation) {
        // TODO: warn
        return FALLBACK_AUTHS;
      }

      const consultant = relation.users.find(
        user => user.userId === store.config.userConfig.email
      );

      if (consultant && consultant.authorities?.length) {
        return consultant.authorities;
      }

      return relation.authorities;
    } else {
      return store.config.userConfig.authorities;
    }
  });

  return authorities || FALLBACK_AUTHS;
};
