import PropTypes from 'prop-types';
import {createSlice} from '@reduxjs/toolkit';

export const downloadShape = PropTypes.shape({
  downloadId: PropTypes.string,
  downloadDone: PropTypes.bool.isRequired,
  downloadPending: PropTypes.bool.isRequired
});

export const initialState = {
  downloadPending: false,
  downloadDone: false,
  downloadId: null,
  downloadError: null,
  lastDownloadId: null
};

const slice = createSlice({
  name: 'download',
  initialState,
  reducers: {
    startDownload: (state, action) => {
      state.downloadPending = true;
      state.downloadId = action.payload;
    },

    downloadSuccess: state => {
      state.downloadPending = false;
      state.downloadDone = true;
      state.downloadError = null;
    },

    downloadError: (state, action) => {
      state.downloadPending = false;
      state.downloadDone = true;
      state.downloadId = null;
      state.downloadError = action.payload;
    },

    closeDownload: state => {
      state.lastDownloadId = state.downloadId;
      state.downloadPending = false;
      state.downloadDone = false;
      state.downloadId = null;
      state.downloadError = null;
    }
  }
});

export const {startDownload, downloadSuccess, downloadError, closeDownload} =
  slice.actions;

export default slice.reducer;
