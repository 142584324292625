import styled from 'styled-components';

export const SelectedPartnerBoxLabel = styled.div`
  // TODO: color probably from theme?
  color: #002976;
  font-weight: 700;
  line-height: 10px;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const SelectedCompanyBox = styled.div`
  padding: 20px;
  height: 75px;
  background: #e5eeff;
`;
