import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// the ancient CustomEvent library (a transitive dependency of the textarea
// autocomplete library that we use) relies on having `global` defined
window.global ||= window;

function prepare() {
  const shouldMockMonitor = !!localStorage.getItem('mockMonitorApi');
  const shouldMockElena = !!localStorage.getItem('mockElenaApi');

  if (
    // @ts-expect-error TODO
    import.meta.env.MODE === 'development' &&
    (shouldMockMonitor || shouldMockElena)
  ) {
    return import('./mocks/browser').then(({worker}) => {
      return worker.start({
        // TODO: make configurable
        onUnhandledRequest: 'bypass'
      });
    });
  }

  return Promise.resolve();
}

prepare()
  .then(() => {
    ReactDOM.render(
      <App />,
      document.getElementById('root') || document.createElement('div')
    );
  })
  .catch(error => {
    console.error(error);
  });
