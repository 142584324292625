import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Grid, Icon, Modal} from 'semantic-ui-react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {FormattedMessage, useIntl} from 'react-intl';
import axios from 'axios';
import {useSelectedCompany} from '@/reducers/monitorConfig';
import {closePartnerRelationsModal} from '@/reducers/partnerRelations';
import {
  usePartnerRelations,
  usePartnerRelationsModal
} from '@/hooks/monitorConfigHooks';
import {SelectedCompanyBox} from '../Styles/PartnerSelectionStyles';
import {changeSubCompany, setCompanyNode} from '@/reducers/userConfig';

export const EcosioLogo = styled.img`
  width: 110px;
  height: 21px;
`;

export const Header = styled.h1`
  font-size: 40px;
  line-height: 48px;
  font-weight: bold;
`;

export const SubHeader = styled.div``;

export const HeaderWrapper = styled.div`
  color: #002268;
`;

export const ModalWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  width: 35rem;
  margin-left: auto;
  margin-right: auto;
`;

const SelectedCompanyName = styled.span`
  font-weight: 700;
  line-height: 16px;
  font-size: 14px;
  color: #000;
`;

const NoPartnerSelected = styled.div`
  height: 16px;
  width: 122px;
  left: 20px;
  top: 37px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
`;

const actionButtonStyle = {
  textAlign: 'left'
};

const buttonIconStyle = {
  float: 'right'
};

/**
 * https://www.figma.com/file/qKr5BAVUzkCadZuUqVMxGa/ecosio-Monitor?node-id=1815%3A62245
 */
const SelectPartnerRelationModal = () => {
  const dispatch = useDispatch();
  const modalOpen = usePartnerRelationsModal();
  const intl = useIntl();
  const partnerRelations = usePartnerRelations();
  const {selectedSubCompany: selectedCompany} = useSelectedCompany();
  const history = useHistory();
  const userConfig = useSelector(state => state.config?.userConfig);

  const [selectedOption, setSelectedOption] = useState(selectedCompany);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedOption(selectedCompany);
  }, [selectedCompany]);

  const partnerOptions = partnerRelations.map(relation => ({
    key: relation.uuid,
    text: relation.name,
    value: relation.uuid
  }));

  const getSelectedRelation = () => {
    if (selectedOption === userConfig?.company?.uuid) {
      return userConfig.company;
    }

    return partnerRelations.find(relation => relation.uuid === selectedOption);
  };

  const doChangeCompany = async companyUuid => {
    try {
      setLoading(true);

      const {data: node} = await axios.get(
        `/api/config/companies/${companyUuid}`
      );

      await dispatch(setCompanyNode(node));

      await dispatch(
        changeSubCompany({
          companyUuid: companyUuid
        })
      );
      dispatch(closePartnerRelationsModal());
      history.push('/');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const changeToSelectedCompany = async () => {
    const relation = getSelectedRelation();
    await doChangeCompany(relation.uuid);
  };

  const changeToMainCompany = async () => {
    await doChangeCompany(userConfig.company.uuid);
  };

  const selectedRelation = getSelectedRelation();

  const ownCompanySelected = selectedOption === userConfig?.company?.uuid;

  const selectPartnerButtonEnabled =
    partnerOptions.length > 0 && selectedRelation;

  const selectPartnerButtonStyle = {color: '#000'};

  if (!selectPartnerButtonEnabled) {
    selectPartnerButtonStyle['background'] = '#F5F5F5';
    selectPartnerButtonStyle['color'] = '#ADAEAE';
    selectPartnerButtonStyle['cursor'] = 'not-allowed';
  }

  return (
    <Modal open={modalOpen} size="large">
      <Modal.Content>
        <ModalWrapper>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <EcosioLogo
                  id="ecosio-logo"
                  src="https://assets.ecosio.com/98.0.0-beta.15/monitor/themes/monitor/assets/images/Logo.png"
                  alt="ecosio logo"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <HeaderWrapper>
                  <Header>
                    <FormattedMessage id="MONITOR_PARTNER_MODE" />
                  </Header>
                  {partnerOptions.length > 0 ? (
                    <SubHeader data-spec="has-partners">
                      <FormattedMessage id="MONITOR_PARTNER_SELECT_HEADER_DESC" />
                    </SubHeader>
                  ) : (
                    <SubHeader data-spec="has-no-partners">
                      <FormattedMessage id="MONITOR_PARTNER_NO_RELATIONS_FOR_PARTNER" />
                    </SubHeader>
                  )}
                </HeaderWrapper>
              </Grid.Column>
            </Grid.Row>
            {partnerOptions.length > 0 && (
              <>
                <Grid.Row>
                  <Grid.Column>
                    <Dropdown
                      data-spec="relations"
                      fluid
                      search
                      onChange={(e, {value}) => setSelectedOption(value)}
                      options={partnerOptions}
                      placeholder={intl.formatMessage({
                        id: 'MONITOR_SELECT_PARTNER'
                      })}
                      selection
                      value={selectedOption}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <>
                      <div
                        style={{
                          color: '#002976',
                          fontWeight: 700,
                          lineHeight: '10px',
                          fontSize: '10px',
                          textTransform: 'uppercase',
                          marginBottom: '10px'
                        }}>
                        <FormattedMessage id="MONITOR_SELECTED_PARTNER" />
                      </div>
                      <SelectedCompanyBox data-spec="select-company-box">
                        <div style={{display: 'flex'}}>
                          <div style={{flex: 8}}>
                            <SelectedCompanyName>
                              {selectedRelation && !ownCompanySelected ? (
                                selectedRelation.name
                              ) : (
                                <>
                                  <div>
                                    <FormattedMessage id="MONITOR_SELECT_PARTNER" />
                                  </div>
                                  <NoPartnerSelected>
                                    <FormattedMessage id="MONITOR_NO_PARTNER_SELECTED" />
                                  </NoPartnerSelected>
                                </>
                              )}
                            </SelectedCompanyName>
                          </div>
                          <div
                            style={{
                              flex: 2,
                              textAlign: 'right',
                              paddingTop: 8,
                              color: '#0054FF'
                            }}>
                            {selectedRelation && !ownCompanySelected && (
                              <Icon name="checkmark" />
                            )}
                          </div>
                        </div>
                      </SelectedCompanyBox>
                    </>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}

            <Grid.Row>
              <Grid.Column>
                <Button
                  data-spec="selected-company"
                  fluid
                  loading={loading}
                  disabled={ownCompanySelected || loading}
                  secondary
                  onClick={changeToSelectedCompany}
                  style={Object.assign(
                    selectPartnerButtonStyle,
                    actionButtonStyle
                  )}>
                  <FormattedMessage id="MONITOR_VIEW_PARTNER" />
                  <Icon
                    name="chevron right"
                    style={Object.assign({}, buttonIconStyle)}
                  />
                </Button>
                <Button
                  fluid
                  loading={loading}
                  disabled={loading}
                  onClick={changeToMainCompany}
                  style={Object.assign({marginTop: '9px'}, actionButtonStyle)}>
                  <FormattedMessage id="MONITOR_PROCEED_WITH_MAIN" />
                  <Icon
                    name="chevron right"
                    style={Object.assign({}, buttonIconStyle)}
                  />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ModalWrapper>
      </Modal.Content>
    </Modal>
  );
};

export default SelectPartnerRelationModal;
