import React from 'react';
import axios from 'axios';
import {Provider} from 'react-redux';
import {ConnectedIntlProvider} from '@ecosio/locales';
import {ErrorBoundary} from '@ecosio/components';
import {QueryClientProvider} from 'react-query';
import AppRouter from './AppRouter';
import store, {history} from './store';
import './App.css';
import logger from './logger';
import ConfigLoader from './ConfigLoader';
import createQueryClient from './helpers/queryClient';
import qs from 'qs';
import {
  determineEnvironmentFromLocation,
  getXSRFCookieNameForEnvironment
} from '@ecosio/auth';

const debug = logger('App');

let BASE_URL = '/monitor';
const XSRF_HEADER = 'X-XSRF-TOKEN';

// just for testing static assets on dev
if (window.location.hostname === 'static.ecosio.com') {
  BASE_URL = 'https://monitor-api.dev.ecosio.com';
}

debug(`Axios baseUrl: ${BASE_URL}`);

const csrfCookieName = getXSRFCookieNameForEnvironment();
const environment = determineEnvironmentFromLocation(window.location.href);

debug(`Setting csrf cookie name for ${environment} env to ${csrfCookieName}`);

// allow interceptors to set parameters via a params property
axios.defaults.params = {};
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.xsrfCookieName = csrfCookieName;
axios.defaults.xsrfHeaderName = XSRF_HEADER;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.baseURL = BASE_URL;
axios.defaults.paramsSerializer = {
  serialize: params => qs.stringify(params, {arrayFormat: 'repeat'})
};

let userTimeZone: string | undefined = undefined;

try {
  userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
} catch (error) {
  console.error(error);
}

axios.interceptors.request.use(
  config => {
    if (userTimeZone) {
      // see TimeZoneFilter.java
      config.headers.set('X-Time-Zone', userTimeZone);
    }
    return config;
  },
  // eslint-disable-next-line promise/no-promise-in-callback
  error => Promise.reject(error)
);

const queryClient = createQueryClient();

const App = () => (
  //  redux
  <Provider store={store}>
    {/* react-intl */}
    <ConnectedIntlProvider respectTimeZone>
      {/* handle global errors */}
      <ErrorBoundary exceptionLogger={console.error} axios={axios}>
        {/* analytics */}
        {/* react-query */}
        <QueryClientProvider client={queryClient}>
          {/* handles global configuration loading / authentication redirects */}
          {/* from here onwards a valid redux store with configuration data is guaranteed */}
          <ConfigLoader>
            {/* the actual app router */}
            <AppRouter history={history} />
          </ConfigLoader>
        </QueryClientProvider>
      </ErrorBoundary>
    </ConnectedIntlProvider>
  </Provider>
);

export default App;
