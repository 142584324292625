import {QueryClient} from 'react-query';
import axios from 'axios';
import defaultLogger from '../logger';

const debug = defaultLogger('queryClient');

const queryRetries = 3;
const retryableErrorCodes = [502, 503, 504];

export default function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // I don't think that we really want that
        refetchOnWindowFocus: false,
        retry: (count, error) => {
          const status = axios.isAxiosError(error)
            ? error.response?.status || 500
            : 500;
          const shouldRetry =
            axios.isAxiosError(error) &&
            count <= queryRetries &&
            retryableErrorCodes.includes(status);
          debug(
            'retrying request: %s, attempt %s, error: %s',
            shouldRetry,
            count,
            error
          );
          return shouldRetry;
        }
      }
    }
  });
}
