import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {Icon} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {useCompanyNode} from '../../reducers/monitorConfig';
import {openPartnerRelationsModal} from '../../reducers/partnerRelations';

import {debugSubCompany} from '../../logger';
import {useMonitorConfig} from '../../hooks/monitorConfigHooks';
import {useCompany} from '../../hooks/user';

const debugCompany = debugSubCompany('partner-relation-selector');

const FixedHeaderMenu = styled.div`
  position: fixed;
  background: ${props => (props.showBackground ? '#d9e6ff' : 'transparent')};
  right: 0;
  left: 215px;
  height: 10px;
  top: 64px;
  z-index: 999;
`;

FixedHeaderMenu.propTypes = {
  showBackground: PropTypes.bool.isRequired
};

const CurrentPartnerBox = styled.div`
  position: fixed;
  height: 42px;
  width: auto;
  min-width: 240px;
  right: 0;
`;

const SelectionTrigger = styled.div`
  cursor: pointer;
  background: #d9e6ff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  height: 33px;
  line-height: 12px;
  font-size: 10px;
  color: #002268;
`;

const PartnerRelationSelector = () => {
  const dispatch = useDispatch();

  const monitorConfig = useMonitorConfig();
  const {subCompanies} = monitorConfig;

  const companyNode = useCompanyNode();

  const selectedSubCompany = companyNode.uuid;
  const selectedSubCompanyName = companyNode.name;

  const {userConfigCompanyUuid: ownCompanyUuid, isPartner} = useCompany();

  if (!isPartner) {
    return null;
  }

  const hasRelations = Array.isArray(subCompanies) && subCompanies.length > 0;

  const showCompanyName =
    selectedSubCompanyName && selectedSubCompany !== ownCompanyUuid;

  debugCompany(
    `Rendering selector with selected subCompany ${selectedSubCompanyName}, showCompanyName(${showCompanyName}), hasRelations(${hasRelations})`
  );

  return (
    <FixedHeaderMenu
      data-spec="partner-relation-menu"
      showBackground={showCompanyName}>
      <CurrentPartnerBox>
        <SelectionTrigger
          data-spec="partner-relation-trigger"
          onClick={() => dispatch(openPartnerRelationsModal())}>
          <Icon name="exchange" />
          {showCompanyName && selectedSubCompanyName}
          {!showCompanyName && hasRelations && (
            <FormattedMessage id="MONITOR_SELECT_PARTNER" />
          )}
          {/* https://ecosio.slack.com/archives/CSVV2DARY/p1643192710118700?thread_ts=1643183238.115300&cid=CSVV2DARY */}
          {!showCompanyName && !hasRelations && (
            <FormattedMessage id="MONITOR_PARTNER_MODE" />
          )}
        </SelectionTrigger>
      </CurrentPartnerBox>
    </FixedHeaderMenu>
  );
};

export default PartnerRelationSelector;
