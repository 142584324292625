import {NewsFeedbackUserConfiguration} from '@/generated/monitor-api.schemas';
import {handleError} from '@/helpers/utils';
import {
  ClickOutsideDivCloseFunction,
  FeatureBaseIFrameComponent
} from '@ecosio/components';
import axios from 'axios';
import React, {useEffect, useState} from 'react';

interface Props {
  newsFeedbackConfiguration: NewsFeedbackUserConfiguration;
  closeViewCallbackFn: () => void;
}

const NewsAndFeedback = ({
  newsFeedbackConfiguration,
  closeViewCallbackFn
}: Props) => {
  const [featurebaseSSOToken, setFeaturebaseSSOToken] = useState('');
  const [triggerFeaturebaseAppClosing, setTriggerFeaturebaseAppClosing] =
    useState(0);

  useEffect(() => {
    const loadSSOToken = async () => {
      try {
        const response = await axios.get(
          newsFeedbackConfiguration.fetchSSOTokenUrl
        );
        setFeaturebaseSSOToken(response.data.ssoToken);
      } catch (error) {
        handleError(error);
      }
    };
    loadSSOToken();
  }, [newsFeedbackConfiguration.fetchSSOTokenUrl]);

  const closeFeatureBaseApp = () => {
    setTriggerFeaturebaseAppClosing(0);
    closeViewCallbackFn();
  };

  // Due to open/close animation in the FeatureBaseIFrameComponent, parent app needs to
  // signal closing action with any > 0 integer ando once the animation is done
  // FeatureBaseIFrameComponent for call the provided closeIFrameFn function
  // in this case - closeFeatureBaseApp()
  const signalFeatureBaseAppClosing = () => {
    setTriggerFeaturebaseAppClosing(triggerFeaturebaseAppClosing + 1);
  };

  return (
    <div data-spec="feature-base-wrapper">
      <ClickOutsideDivCloseFunction onClick={signalFeatureBaseAppClosing}>
        {featurebaseSSOToken?.length > 0 && (
          <FeatureBaseIFrameComponent
            featureBaseConfig={newsFeedbackConfiguration}
            ssoToken={featurebaseSSOToken}
            closeIFrameFn={closeFeatureBaseApp}
            closeIFrameTriggerFlag={triggerFeaturebaseAppClosing}
          />
        )}
      </ClickOutsideDivCloseFunction>
    </div>
  );
};

export default NewsAndFeedback;
