import PropTypes from 'prop-types';

export const locationShape = PropTypes.shape({
  search: PropTypes.string.isRequired
});

export const historyShape = PropTypes.shape({
  push: PropTypes.func.isRequired
});

export const filterShape = PropTypes.shape({
  createdFrom: PropTypes.string,
  createdTo: PropTypes.string,
  senderUuid: PropTypes.string,
  receiverUuid: PropTypes.string
});

export const downloadRequestShape = PropTypes.shape({
  id: PropTypes.number
});

export const renderConfigurationShape = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  documentTypeId: PropTypes.string.isRequired,
  restrictedPartnerUuids: PropTypes.arrayOf(PropTypes.string),
  downloadButtonTranslationKey: PropTypes.string.isRequired,
  downloadButtonIcon: PropTypes.string
});

/**
 * @typedef {Object} SubCompanyConfig
 * @property {string} resendState - if empty, resend is disabled. Otherwise the state for resending.
 * @property {string} resendWithMutationState
 * @property
 */
export const subCompanyConfigShape = PropTypes.shape({
  resendState: PropTypes.string,
  resendWithMutationState: PropTypes.string,
  documentRenderingConfigurations: PropTypes.arrayOf(renderConfigurationShape)
    .isRequired
});

/**
 * @typedef {Object} SubCompany
 * @property {string} uuid - the uuid of the company
 * @property {string} name - the name of the company
 * @property {SubCompanyConfig} - the configuration for this company
 */
export const companyShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  config: subCompanyConfigShape
});

/**
 * The configuration of the monitor for the logged-in user
 *
 * TODO: i think this definition is somewhat wrong, as some of the properties
 * are inside the "data" node. See comment in the RESPONSE reducer of the monitorConfig.
 *
 * @typedef  {Object} MonitorConfig
 * @property {string} owningCompanyUuid - the uuid of the company the user belongs to
 * @property {string} owningCompanyName- the name of the company the user belongs to
 * @property {Array.<SubCompany>} subCompanies - list of subcompanies, either real subcompanies, of partners in case of partner relations
 * @property {string} companyType - CompanyEntity.CompanyType enum value
 * @property {string} mismatchedSubCompanyUuid - in case the user requests a subCompany which is currently not selected (direct URL), this is returned from the backend
 * @property {boolean} showPartnerRelationsMismatchModal - whether the partner-relations-mismatch modal should be shown
 * @property {boolean} showPartnerRelationsModal - whether the partner-relations-select modal should be shown
 * @property {boolean} isPartner - whether the companyType is PARTNER
 * @property {boolean} partnerMatrixEnabled - whether the partner matrix feature is enabled
 * @property {boolean} supportTicketPageEnabled - whether the support ticket page is enabled
 * @property {boolean} includeSubCompany - whether to resolve sub-company uuids for backend requests
 * @see MonitorConfigurationDto.java
 */
export const monitorConfigShape = PropTypes.shape({
  documentRenderingConfigurations: PropTypes.arrayOf(renderConfigurationShape)
    .isRequired,
  supportChatEnabled: PropTypes.bool.isRequired,
  resendState: PropTypes.string,
  resendWithMutationState: PropTypes.string,
  ackDownloadEnabled: PropTypes.bool.isRequired,
  partnerMatrixEnabled: PropTypes.bool.isRequired,
  supportTicketPageEnabled: PropTypes.bool.isRequired
});

/**
 * @typedef {Object} UserConfiguration
 * @property {string} email
 * @property {Array.<string>} authorities
 * @property {SubCompany} company
 */
export const userConfigShape = PropTypes.shape({
  email: PropTypes.string.isRequired,
  authorities: PropTypes.arrayOf(PropTypes.string).isRequired
});
