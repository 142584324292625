import PropTypes from 'prop-types';
import axios from 'axios';
import logger from '../../logger';

const debug = logger('settingsApi');

/**
 * Takes the list of connectors from the backend and groups
 * them by inbound/outbound.
 *
 * @param result
 * @returns {{inbound: *[], outbound: *[]}|*}
 */
export const prepareConnectors = result => {
  /**
   * @type {{inbound: Array.<BaseConnector>, outbound: BaseConnector[]}}
   */
  const connectors = {inbound: [], outbound: []};
  if (!Array.isArray(result?.data)) {
    console.warn('Invalid argument for prepareConnectors');
    return connectors;
  }

  /**
   * @param {connectors} acc
   * @param {BaseConnector} connector
   */
  const reducer = (acc, connector) => {
    if (connector.direction === ConnectorType.DELIVERY) {
      // default to true
      connector.allowGenerateUserKey = true;
      acc.inbound.push(connector);
    } else if (connector.direction === ConnectorType.FETCH) {
      acc.outbound.push(connector);
    } else {
      console.warn('Connector has no delivery/fetch type: ', connector);
    }

    // this one is part of a pair, link it to it's parent for later usage
    if (connector.copyOf) {
      const connectorRef = result.data.find(
        conn => conn.uuid === connector.copyOf
      );

      if (connectorRef) {
        if (!Array.isArray(connectorRef.pairedConnectors)) {
          connectorRef.pairedConnectors = [];
        }

        connectorRef.pairedConnectors.push(connector);
      }
    }
    return acc;
  };

  const preparedConnectors = result.data.reduce(reducer, connectors);

  // now find API delivery connectors that also have a fetch connector, but without
  // a copyOf field matching the uuid
  preparedConnectors.inbound
    .filter(conn => conn.connector === Connector.API)
    .forEach(conn => {
      const outboundApiConnectors = connectors.outbound.filter(outbound => {
        return outbound.connector === Connector.API;
      });

      // nothing to do
      if (outboundApiConnectors.length === 0) {
        return;
      }

      // now find any outbound api connector that is the paired connector
      // of the delivery connector
      const copyOfConnector = outboundApiConnectors.find(
        outbound => outbound.copyOf === conn.uuid
      );

      // if we don't find such an outbound connector, the delivery connector
      // is not paired and we disable user key generation
      // https://gitlab.ecosio.com/code/monitor/-/issues/194
      // https://gitlab.ecosio.com/code/monitor/-/issues/253
      if (typeof copyOfConnector === 'undefined') {
        conn.allowGenerateUserKey = false;
      }
    });

  return preparedConnectors;
};

/**
 * Creates a UserKeyUpdate.java request body for the regenerate-user-key POST request
 * @param connector
 * @returns {{connectorUuid, version}}
 */
export const createConnectorUpdate = connector => {
  return {
    connectorUuid: connector.uuid,
    version: connector.version
  };
};

export const generateSftpPassword = (uuid, version) => {
  if (!uuid) {
    return Promise.reject('No connector / uuid provided');
  }

  const passwordUpdate = {
    connectorUuid: uuid,
    version: version
  };
  return axios.post(
    `/api/connectors/sftp/regenerate-password`,
    JSON.stringify(passwordUpdate)
  );
};

export const generateUserKey = connector => {
  if (!connector?.uuid) {
    return Promise.reject('No connector / uuid provided');
  }

  let uri;

  switch (connector.connector) {
    case Connector.MASTERDATA:
      uri = `/api/connectors/masterdata/regenerate-user-key`;
      break;
    case Connector.API:
      uri = `/api/connectors/api/regenerate-user-key`;
      break;
    default:
      return Promise.reject(
        `Unsupported connector to regenerate-user-key ${connector.uuid} ${connector.connector}`
      );
  }

  const userKeyUpdate = createConnectorUpdate(connector);
  return axios.post(uri, JSON.stringify(userKeyUpdate));
};

export const loadSftpServerConnectors = async () => {
  const result = await axios.get(`/api/connectors/sftp`);
  return prepareConnectors(result);
};

export const loadSftpConnector = uuid => {
  if (!uuid) {
    return Promise.reject('Unable to fetch a connector without uuid');
  }

  return axios.get(`/api/connectors/sftp/${uuid}`);
};

export const loadMasterdataConnectors = async () => {
  const result = await axios.get(`/api/connectors/masterdata`);
  return prepareConnectors(result);
};

export const loadMasterdataConnector = uuid => {
  return axios.get(`/api/connectors/masterdata/${uuid}`);
};

export const loadApiConnector = uuid => {
  if (!uuid) {
    return Promise.reject('Unable to fetch a connector without uuid');
  }

  return axios.get(`/api/connectors/api/${uuid}`);
};

export const loadApiConnectors = async () => {
  const result = await axios.get(`/api/connectors/api`);
  return prepareConnectors(result);
};

export const GET_CONNECTOR_COUNT_ENDPOINT =
  '/api/config/monitor/connector-count';

export const getConnectorCount = () => {
  return axios.get(GET_CONNECTOR_COUNT_ENDPOINT);
};

export const loadConnector = (type, uuid) => {
  debug(`Loading connector ${uuid} / ${type}`);
  switch (type) {
    case Connector.MASTERDATA:
      return loadMasterdataConnector(uuid);
    case Connector.API:
      return loadApiConnector(uuid);
    default:
      return Promise.reject(
        `Unsupported connector type to load ${type} (${uuid}) `
      );
  }
};
/**
 * @readonly
 * @enum {ConnectorType}
 */
export const ConnectorType = {
  FORWARDER: 'FORWARDER',
  RECEIVER: 'RECEIVER',
  DELIVERY: 'DELIVERY', // ==> MESSAGES TO ECOSIO ( == DELIVERY CONNECTOR )
  LOCAL_STATION: 'LOCAL_STATION',
  FETCH: 'FETCH', // ==> MESSAGES FROM ECOSIO ( == FORWARD CONNECTOR)
  VIRTUAL_LOCAL_STATION: 'VIRTUAL_LOCAL_STATION',
  SENDER_SPECIFIC_FORWARDER: 'SENDER_SPECIFIC_FORWARDER'
};

/**
 * @readonly
 * @enum {Connector}
 */
export const Connector = {
  API: 'API',
  SFTP_SERVER: 'SFTP_SERVER',
  MASTERDATA: 'MASTERDATA'
};

/**
 * @typedef {Object} BaseConnector
 * @property {string} uuid - the uuid of the company
 * @property {ConnectorType} direction - the delivery connection
 * @property {ConnectorType} connector - the connector type
 * @property {string} copyOf - the uuid of the copy connector
 */

/**
 * @typedef {Object} ApiConnector
 * @augments BaseConnector
 * @property {string} userId - userId for authentication
 * @property {string} userKey - userKey for authentication
 */
export const apiConnectorShape = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  direction: PropTypes.oneOf([ConnectorType.DELIVERY, ConnectorType.FETCH]),
  userId: PropTypes.string,
  userKey: PropTypes.string,
  maxMessageSize: PropTypes.number,
  mode: PropTypes.number,
  requestCallback: PropTypes.bool,
  copyOf: PropTypes.string,
  appKey: PropTypes.string,
  connector: PropTypes.oneOf([
    Connector.API,
    Connector.SFTP_SERVER,
    Connector.MASTERDATA
  ]),
  pairedConnectors: PropTypes.array,
  allowGenerateUserKey: PropTypes.bool
});

export const sftpConnectorShape = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  direction: PropTypes.oneOf([ConnectorType.DELIVERY, ConnectorType.FETCH]),
  username: PropTypes.string,
  password: PropTypes.string,
  maxMessageSize: PropTypes.number,
  copyOf: PropTypes.string,
  appKey: PropTypes.string,
  connector: PropTypes.oneOf([Connector.SFTP_SERVER]),
  pickedUpOnDelete: PropTypes.bool,
  pairedConnectors: PropTypes.array
});

export const masterdataConnectorShape = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  direction: PropTypes.oneOf([ConnectorType.DELIVERY, ConnectorType.FETCH]),
  userId: PropTypes.string,
  userKey: PropTypes.string,
  appKey: PropTypes.string,
  connector: PropTypes.oneOf([Connector.MASTERDATA]),
  shkConnect: PropTypes.bool
});
