/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: @env.CI_COMMIT_REF_NAME@
 */
import axios from 'axios';
import type {AxiosRequestConfig, AxiosResponse} from 'axios';
import type {
  ApiConnectorDto,
  AuthorizeClientParams,
  AuthorizedParams,
  AutoCompleteSuggestion,
  ClearSetupSessionParams,
  ClientCredentialsDto,
  CompanyNode,
  CreatePreviewFilenameParams,
  DebugParams,
  DownloadAcknowledgementParams,
  DownloadAttachmentParams,
  DownloadFullMessagesParams,
  DownloadMessageContentParams,
  DownloadUserArchiveEntityParams,
  EInvoiceConnectorDetailsDto,
  EInvoiceConnectorListItemDto,
  EdiPartnerData,
  EdiPartnerNames,
  EvaluateBooleanFlag1Params,
  EvaluateBooleanFlagParams,
  EvaluateObjectFlag1Params,
  EvaluateObjectFlagParams,
  ExceptionData,
  Get1Params,
  GetCompanyListParams,
  GetConfigurationParams,
  GetConnectorCountParams,
  GetConnectorDetails1Params,
  GetConnectorDetailsParams,
  GetConnectorParams,
  GetCountParams,
  GetDocTypesClassificationsParams,
  GetDocumentTypeClassificationsParams,
  GetDocumentTypeDistributionParams,
  GetEDIRelationPartnersParams,
  GetEInvoiceConnectorsList1Params,
  GetEInvoiceConnectorsListParams,
  GetEdiPartnerDataParams,
  GetEnabledModulesParams,
  GetExceptionsParams,
  GetFilenamePatterParams,
  GetFixedExceptionsParams,
  GetMessageHistogramParams,
  GetMessageParams,
  GetMessageStatisticsCsvParams,
  GetMessageStatisticsParams,
  GetMessageToResendParams,
  GetMessagesParams,
  GetParams,
  GetPartnerListParams,
  GetPartnersAndMappingsDataParams,
  GetPartnersParams,
  GetS3MessageHeaderParams,
  GetSSOTokenParams,
  GetSubCompaniesParams,
  GetUserArchiveStatusParams,
  GetUserConfigParams,
  GetUserParams,
  LineChartData,
  ListApiConnectorsParams,
  ListMasterdataConnectorsParams,
  ListParams,
  ListSftpServerConnectorsParams,
  MasterdataConnectorDto,
  MessageCountDto,
  MessageMonitoringDetailsDto,
  MessageMonitoringListDto,
  MessageStatistics,
  MonitorConfiguration,
  NewsFeedbackUserConfiguration,
  PageEDIPartnerDTO,
  PageEDIRelationPartnerDTO,
  PageUserArchiveDto,
  PartnerAndMappingData,
  PasswordUpdate,
  PathPatternDto,
  PieChartData,
  ProviderEvaluationBoolean,
  ProviderEvaluationValue,
  RegeneratePasswordParams,
  RegenerateUserKey1Params,
  RegenerateUserKeyParams,
  RenderStatusInformationParams,
  ResendParams,
  SSOAuthenticationHolder,
  Save1Params,
  Save200,
  SaveParams,
  SelectSubCompany,
  SetHandledCustomer200,
  SetHandledCustomerParams,
  SftpServerConnectorDto,
  SftpServerUpdateResponse,
  UpdateAndFetchUserNewsFeedbackConfigurationParams,
  UpdateSelectedSubCompanyDefault,
  UpdateSelectedSubCompanyParams,
  UserArchiveRequestEntity,
  UserConfiguration,
  UserKeyRegeneratedResponse,
  UserKeyUpdate,
  UserResponse,
  Value
} from './monitor-api.schemas';

export const updateSelectedSubCompany = <
  TData = AxiosResponse<UpdateSelectedSubCompanyDefault>
>(
  selectSubCompany: SelectSubCompany,
  params?: UpdateSelectedSubCompanyParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/api/user/select-sub-company`, selectSubCompany, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const setHandledCustomer = <
  TData = AxiosResponse<SetHandledCustomer200>
>(
  messageId: string,
  params?: SetHandledCustomerParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/api/messages/${messageId}/set-handled-customer`,
    undefined,
    {
      ...options,
      params: {...params, ...options?.params}
    }
  );
};

export const getMessageToResend = <TData = AxiosResponse<void>>(
  messageId: string,
  params: GetMessageToResendParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/messages/${messageId}/resend`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const resend = <TData = AxiosResponse<void>>(
  messageId: string,
  resendBody: string[],
  params: ResendParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/api/messages/${messageId}/resend`, resendBody, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const regeneratePassword = <
  TData = AxiosResponse<SftpServerUpdateResponse>
>(
  passwordUpdate: PasswordUpdate,
  params?: RegeneratePasswordParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/api/connectors/sftp/regenerate-password`,
    passwordUpdate,
    {
      ...options,
      params: {...params, ...options?.params}
    }
  );
};

export const regenerateUserKey = <
  TData = AxiosResponse<UserKeyRegeneratedResponse>
>(
  userKeyUpdate: UserKeyUpdate,
  params?: RegenerateUserKeyParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/api/connectors/masterdata/regenerate-user-key`,
    userKeyUpdate,
    {
      ...options,
      params: {...params, ...options?.params}
    }
  );
};

export const getEInvoiceConnectorsList = <
  TData = AxiosResponse<EInvoiceConnectorListItemDto[]>
>(
  params?: GetEInvoiceConnectorsListParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/connectors/e-invoice`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const save = <TData = AxiosResponse<Save200>>(
  eInvoiceConnectorDetailsDto: EInvoiceConnectorDetailsDto,
  params?: SaveParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/api/connectors/e-invoice`, eInvoiceConnectorDetailsDto, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getEInvoiceConnectorsList1 = <
  TData = AxiosResponse<EInvoiceConnectorListItemDto[]>
>(
  params?: GetEInvoiceConnectorsList1Params,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/connectors/e-invoice/oauth2`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const save1 = <TData = AxiosResponse<void>>(
  params: Save1Params,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/api/connectors/e-invoice/oauth2`, undefined, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const authorizeClient = <TData = AxiosResponse<void>>(
  clientCredentialsDto: ClientCredentialsDto,
  params: AuthorizeClientParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/api/connectors/e-invoice/oauth2/authorize`,
    clientCredentialsDto,
    {
      ...options,
      params: {...params, ...options?.params}
    }
  );
};

export const regenerateUserKey1 = <
  TData = AxiosResponse<UserKeyRegeneratedResponse>
>(
  userKeyUpdate: UserKeyUpdate,
  params?: RegenerateUserKey1Params,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/api/connectors/api/regenerate-user-key`, userKeyUpdate, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getUser = <TData = AxiosResponse<UserResponse>>(
  userId: string,
  params?: GetUserParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/users/${userId}`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getSubCompanies = <
  TData = AxiosResponse<AutoCompleteSuggestion[]>
>(
  params?: GetSubCompaniesParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/user/sub-companies`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getPartnersAndMappingsData = <
  TData = AxiosResponse<PartnerAndMappingData>
>(
  params?: GetPartnersAndMappingsDataParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/statistics/partners-and-mappings`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getMessageStatistics = <TData = AxiosResponse<MessageStatistics>>(
  params: GetMessageStatisticsParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/statistics/message-stats`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getMessageStatisticsCsv = <TData = AxiosResponse<string>>(
  params: GetMessageStatisticsCsvParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/statistics/message-stats/csv`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getMessageHistogram = <TData = AxiosResponse<LineChartData>>(
  params: GetMessageHistogramParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/statistics/message-histogram`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getFixedExceptions = <TData = AxiosResponse<LineChartData>>(
  params?: GetFixedExceptionsParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/statistics/fixed-exceptions`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getExceptions = <TData = AxiosResponse<ExceptionData>>(
  params?: GetExceptionsParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/statistics/exceptions`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getEdiPartnerData = <TData = AxiosResponse<EdiPartnerData>>(
  params: GetEdiPartnerDataParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/statistics/edi-partners`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getDocumentTypeDistribution = <
  TData = AxiosResponse<PieChartData>
>(
  params: GetDocumentTypeDistributionParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/statistics/document-types`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getDocumentTypeClassifications = <TData = AxiosResponse<string[]>>(
  params?: GetDocumentTypeClassificationsParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/statistics/document-type-classifications`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getSSOToken = <TData = AxiosResponse<SSOAuthenticationHolder>>(
  params?: GetSSOTokenParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/newsFeedback/ssoToken`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const updateAndFetchUserNewsFeedbackConfiguration = <
  TData = AxiosResponse<NewsFeedbackUserConfiguration>
>(
  params?: UpdateAndFetchUserNewsFeedbackConfigurationParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/newsFeedback/config`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getMessages = <TData = AxiosResponse<MessageMonitoringListDto[]>>(
  params?: GetMessagesParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/messages`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getMessage = <TData = AxiosResponse<MessageMonitoringDetailsDto>>(
  messageId: string,
  params?: GetMessageParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/messages/${messageId}`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const renderStatusInformation = <TData = AxiosResponse<Blob>>(
  messageId: string,
  sieId: number,
  renderConfigurationUuid: string,
  params: RenderStatusInformationParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(
    `/api/messages/${messageId}/status-information/${sieId}/render/${renderConfigurationUuid}`,
    {
      responseType: 'blob',
      ...options,
      params: {...params, ...options?.params}
    }
  );
};

export const downloadMessageContent = <TData = AxiosResponse<void>>(
  messageId: string,
  sieId: number,
  params: DownloadMessageContentParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(
    `/api/messages/${messageId}/status-information/${sieId}/download`,
    {
      ...options,
      params: {...params, ...options?.params}
    }
  );
};

export const downloadAcknowledgement = <TData = AxiosResponse<Blob>>(
  messageId: string,
  sieId: number,
  params?: DownloadAcknowledgementParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(
    `/api/messages/${messageId}/status-information/${sieId}/acknowledgement`,
    {
      responseType: 'blob',
      ...options,
      params: {...params, ...options?.params}
    }
  );
};

export const downloadAttachment = <TData = AxiosResponse<Blob>>(
  messageId: string,
  params: DownloadAttachmentParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/messages/${messageId}/attachment`, {
    responseType: 'blob',
    ...options,
    params: {...params, ...options?.params}
  });
};

export const list = <TData = AxiosResponse<PageUserArchiveDto>>(
  params?: ListParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/messages/user-archives`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const downloadUserArchiveEntity = <TData = AxiosResponse<Blob>>(
  uuid: UserArchiveRequestEntity,
  params?: DownloadUserArchiveEntityParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/messages/user-archive/${uuid}`, {
    responseType: 'blob',
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getUserArchiveStatus = <TData = AxiosResponse<string>>(
  uuid: UserArchiveRequestEntity,
  params?: GetUserArchiveStatusParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/messages/user-archive/${uuid}/status`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getS3MessageHeader = <TData = AxiosResponse<string>>(
  params: GetS3MessageHeaderParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/messages/headers`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const downloadFullMessages = <TData = AxiosResponse<string>>(
  params?: DownloadFullMessagesParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/messages/download`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getCount = <TData = AxiosResponse<MessageCountDto>>(
  params?: GetCountParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/messages/count`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const evaluateObjectFlag = <
  TData = AxiosResponse<ProviderEvaluationValue>
>(
  key: string,
  defaultValue: Value,
  params?: EvaluateObjectFlagParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/ext/features/object/${key}/${defaultValue}`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const evaluateObjectFlag1 = <
  TData = AxiosResponse<ProviderEvaluationValue>
>(
  key: string,
  defaultValue: Value,
  params?: EvaluateObjectFlag1Params,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/features/object/${key}/${defaultValue}`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const evaluateBooleanFlag = <
  TData = AxiosResponse<ProviderEvaluationBoolean>
>(
  key: string,
  defaultValue: boolean,
  params?: EvaluateBooleanFlagParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/ext/features/boolean/${key}/${defaultValue}`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const evaluateBooleanFlag1 = <
  TData = AxiosResponse<ProviderEvaluationBoolean>
>(
  key: string,
  defaultValue: boolean,
  params?: EvaluateBooleanFlag1Params,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/features/boolean/${key}/${defaultValue}`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getEDIRelationPartners = <
  TData = AxiosResponse<PageEDIRelationPartnerDTO>
>(
  params: GetEDIRelationPartnersParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/edi-relations/partners`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getDocTypesClassifications = <TData = AxiosResponse<string[]>>(
  params: GetDocTypesClassificationsParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/edi-relations/doctypes/classifications`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getPartners = <TData = AxiosResponse<PageEDIPartnerDTO>>(
  params: GetPartnersParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/edi-partners`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getPartnerList = <TData = AxiosResponse<EdiPartnerNames>>(
  params?: GetPartnerListParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/edi-partner`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const listSftpServerConnectors = <
  TData = AxiosResponse<SftpServerConnectorDto[]>
>(
  params?: ListSftpServerConnectorsParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/connectors/sftp`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const get = <TData = AxiosResponse<SftpServerConnectorDto>>(
  uuid: string,
  params?: GetParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/connectors/sftp/${uuid}`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const listMasterdataConnectors = <
  TData = AxiosResponse<MasterdataConnectorDto[]>
>(
  params?: ListMasterdataConnectorsParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/connectors/masterdata`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getConnector = <TData = AxiosResponse<MasterdataConnectorDto>>(
  uuid: string,
  params?: GetConnectorParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/connectors/masterdata/${uuid}`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getConnectorDetails = <
  TData = AxiosResponse<EInvoiceConnectorDetailsDto>
>(
  connectorUuid: string,
  params?: GetConnectorDetailsParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/connectors/e-invoice/${connectorUuid}`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getConnectorDetails1 = <
  TData = AxiosResponse<EInvoiceConnectorDetailsDto>
>(
  connectorUuid: string,
  params?: GetConnectorDetails1Params,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/connectors/e-invoice/oauth2/${connectorUuid}`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const authorized = <TData = AxiosResponse<string>>(
  params: AuthorizedParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/connectors/e-invoice/oauth2/authorized`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const listApiConnectors = <TData = AxiosResponse<ApiConnectorDto[]>>(
  params?: ListApiConnectorsParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/connectors/api`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const get1 = <TData = AxiosResponse<ApiConnectorDto>>(
  uuid: string,
  params?: Get1Params,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/connectors/api/${uuid}`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getUserConfig = <TData = AxiosResponse<UserConfiguration>>(
  params?: GetUserConfigParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/config/user`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getConfiguration = <TData = AxiosResponse<MonitorConfiguration>>(
  companyUuid: string,
  params?: GetConfigurationParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/config/monitor/${companyUuid}`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getConnectorCount = <TData = AxiosResponse<number>>(
  params?: GetConnectorCountParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/config/monitor/connector-count`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getEnabledModules = <TData = AxiosResponse<string>>(
  params?: GetEnabledModulesParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/config/enabled-modules`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getCompanyList = <TData = AxiosResponse<CompanyNode>>(
  companyUuid: string,
  params?: GetCompanyListParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/config/companies/${companyUuid}`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const getFilenamePatter = <TData = AxiosResponse<PathPatternDto>>(
  params?: GetFilenamePatterParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/company/filename-pattern`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const createPreviewFilename = <TData = AxiosResponse<string>>(
  params: CreatePreviewFilenameParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/company/filename-pattern/preview`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const debug = <TData = AxiosResponse<string[]>>(
  params?: DebugParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/company/debug`, {
    ...options,
    params: {...params, ...options?.params}
  });
};

export const clearSetupSession = <TData = AxiosResponse<void>>(
  connectorUuid: string,
  params?: ClearSetupSessionParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.delete(
    `/api/connectors/e-invoice/oauth2/setup/session/${connectorUuid}`,
    {
      ...options,
      params: {...params, ...options?.params}
    }
  );
};

export type UpdateSelectedSubCompanyResult =
  AxiosResponse<UpdateSelectedSubCompanyDefault>;
export type SetHandledCustomerResult = AxiosResponse<SetHandledCustomer200>;
export type GetMessageToResendResult = AxiosResponse<void>;
export type ResendResult = AxiosResponse<void>;
export type RegeneratePasswordResult = AxiosResponse<SftpServerUpdateResponse>;
export type RegenerateUserKeyResult = AxiosResponse<UserKeyRegeneratedResponse>;
export type GetEInvoiceConnectorsListResult = AxiosResponse<
  EInvoiceConnectorListItemDto[]
>;
export type SaveResult = AxiosResponse<Save200>;
export type GetEInvoiceConnectorsList1Result = AxiosResponse<
  EInvoiceConnectorListItemDto[]
>;
export type Save1Result = AxiosResponse<void>;
export type AuthorizeClientResult = AxiosResponse<void>;
export type RegenerateUserKey1Result =
  AxiosResponse<UserKeyRegeneratedResponse>;
export type GetUserResult = AxiosResponse<UserResponse>;
export type GetSubCompaniesResult = AxiosResponse<AutoCompleteSuggestion[]>;
export type GetPartnersAndMappingsDataResult =
  AxiosResponse<PartnerAndMappingData>;
export type GetMessageStatisticsResult = AxiosResponse<MessageStatistics>;
export type GetMessageStatisticsCsvResult = AxiosResponse<string>;
export type GetMessageHistogramResult = AxiosResponse<LineChartData>;
export type GetFixedExceptionsResult = AxiosResponse<LineChartData>;
export type GetExceptionsResult = AxiosResponse<ExceptionData>;
export type GetEdiPartnerDataResult = AxiosResponse<EdiPartnerData>;
export type GetDocumentTypeDistributionResult = AxiosResponse<PieChartData>;
export type GetDocumentTypeClassificationsResult = AxiosResponse<string[]>;
export type GetSSOTokenResult = AxiosResponse<SSOAuthenticationHolder>;
export type UpdateAndFetchUserNewsFeedbackConfigurationResult =
  AxiosResponse<NewsFeedbackUserConfiguration>;
export type GetMessagesResult = AxiosResponse<MessageMonitoringListDto[]>;
export type GetMessageResult = AxiosResponse<MessageMonitoringDetailsDto>;
export type RenderStatusInformationResult = AxiosResponse<Blob>;
export type DownloadMessageContentResult = AxiosResponse<void>;
export type DownloadAcknowledgementResult = AxiosResponse<Blob>;
export type DownloadAttachmentResult = AxiosResponse<Blob>;
export type ListResult = AxiosResponse<PageUserArchiveDto>;
export type DownloadUserArchiveEntityResult = AxiosResponse<Blob>;
export type GetUserArchiveStatusResult = AxiosResponse<string>;
export type GetS3MessageHeaderResult = AxiosResponse<string>;
export type DownloadFullMessagesResult = AxiosResponse<string>;
export type GetCountResult = AxiosResponse<MessageCountDto>;
export type EvaluateObjectFlagResult = AxiosResponse<ProviderEvaluationValue>;
export type EvaluateObjectFlag1Result = AxiosResponse<ProviderEvaluationValue>;
export type EvaluateBooleanFlagResult =
  AxiosResponse<ProviderEvaluationBoolean>;
export type EvaluateBooleanFlag1Result =
  AxiosResponse<ProviderEvaluationBoolean>;
export type GetEDIRelationPartnersResult =
  AxiosResponse<PageEDIRelationPartnerDTO>;
export type GetDocTypesClassificationsResult = AxiosResponse<string[]>;
export type GetPartnersResult = AxiosResponse<PageEDIPartnerDTO>;
export type GetPartnerListResult = AxiosResponse<EdiPartnerNames>;
export type ListSftpServerConnectorsResult = AxiosResponse<
  SftpServerConnectorDto[]
>;
export type GetResult = AxiosResponse<SftpServerConnectorDto>;
export type ListMasterdataConnectorsResult = AxiosResponse<
  MasterdataConnectorDto[]
>;
export type GetConnectorResult = AxiosResponse<MasterdataConnectorDto>;
export type GetConnectorDetailsResult =
  AxiosResponse<EInvoiceConnectorDetailsDto>;
export type GetConnectorDetails1Result =
  AxiosResponse<EInvoiceConnectorDetailsDto>;
export type AuthorizedResult = AxiosResponse<string>;
export type ListApiConnectorsResult = AxiosResponse<ApiConnectorDto[]>;
export type Get1Result = AxiosResponse<ApiConnectorDto>;
export type GetUserConfigResult = AxiosResponse<UserConfiguration>;
export type GetConfigurationResult = AxiosResponse<MonitorConfiguration>;
export type GetConnectorCountResult = AxiosResponse<number>;
export type GetEnabledModulesResult = AxiosResponse<string>;
export type GetCompanyListResult = AxiosResponse<CompanyNode>;
export type GetFilenamePatterResult = AxiosResponse<PathPatternDto>;
export type CreatePreviewFilenameResult = AxiosResponse<string>;
export type DebugResult = AxiosResponse<string[]>;
export type ClearSetupSessionResult = AxiosResponse<void>;
