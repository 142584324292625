import React from 'react';
import styled from 'styled-components';
import {useIntl} from 'react-intl';
import {Icon} from 'semantic-ui-react';
import logger from '../logger';
import {useCompany} from './user';
import {useAppSelector} from '@/hooks';
import {MonitorConfigState} from '@/reducers/monitorConfig';

const debug = logger('usePartnerRelationsModalHook');

export const useAllCompanies = () => {
  return useAppSelector(state => {
    if (state.config.loading) {
      return [];
    }
    return state.config.allCompanies;
  });
};

interface Props {
  level: number;
}

const TreeItem = styled.div<Props>`
  display: inline-block;
  padding-left: ${props => `${props.level * 15}px`};
`;

export const useAllCompaniesDropdownOptions = () => {
  const allCompanies = useAllCompanies();
  const {userConfigCompanyUuid} = useCompany();
  const intl = useIntl();
  return allCompanies.map(company => {
    const textPostFix =
      company.uuid === userConfigCompanyUuid
        ? ` (${intl.formatMessage({id: 'PARENT_COMPANY'})})`
        : '';
    return {
      key: company.uuid,
      value: company.uuid,
      text: `${company.name}${textPostFix}`,
      content: (
        <TreeItem level={company.level}>
          {company.level > 0 && (
            <Icon
              color="grey"
              name="level up alternate"
              className="clockwise rotated"
            />
          )}
          {company.name}
        </TreeItem>
      )
    };
  });
};

/**
 * TODO: move to user.js
 *
 * @return {UserConfiguration}
 */
export const useUserConfig = () => {
  return useAppSelector(store => {
    return store.config.userConfig || {};
  });
};

export const useRootUserConfig = () => {
  return useAppSelector(store => {
    return store.config;
  });
};

/**
 * TODO: before merge check if there's a different to useMonitorConfig
 *
 * @return {SubCompanyConfig} - the configuration of the selected company
 *
 */
export const useSelectedMonitorConfig = () => {
  return useAppSelector(state => {
    return state.monitorConfig;
  });
};

/**
 * @return {MonitorConfig} - the monitor config of the currently selected company
 */
export const useMonitorConfig = (): MonitorConfigState => {
  return useAppSelector(store => {
    return store.monitorConfig;
  });
};

/**
 * @return {Array.<SubCompany>} - subcompanies having the managedCompany flag set to true
 */
export const usePartnerRelations = () => {
  return useAppSelector(store => {
    if (!store.config.userConfig.partnerRelations) {
      return [];
    }
    return store.config.userConfig.partnerRelations.map(relation => {
      return relation.customer;
    });
  });
};

/**
 * @return {boolean} true if the partnerRelationsModal should be opened
 */
export const usePartnerRelationsModal = () => {
  debug('Getting partnerRelationsModal');
  return useAppSelector(store => {
    return store.partnerRelations.showPartnerRelationsSelectModal || false;
  });
};
