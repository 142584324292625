import {createSlice} from '@reduxjs/toolkit';

const initialState = {showPartnerRelationsSelectModal: false};

const partnerRelationSlice = createSlice({
  name: 'partnerRelation',
  initialState,
  reducers: {
    openPartnerRelationsModal(state) {
      state.showPartnerRelationsSelectModal = true;
    },
    closePartnerRelationsModal(state) {
      state.showPartnerRelationsSelectModal = false;
    },
    openPartnerRelationsMismatchModalModal(state, action) {
      state.showPartnerRelationsMismatchModal = true;
      state.mismatchedSubCompanyUuid = action.payload.mismatchedCompanyUuid;
    },
    closePartnerRelationsMismatchModalModal(state) {
      state.showPartnerRelationsMismatchModal = false;
      state.mismatchedSubCompanyUuid = undefined;
    }
  }
});

export const {
  openPartnerRelationsModal,
  closePartnerRelationsModal,
  openPartnerRelationsMismatchModalModal,
  closePartnerRelationsMismatchModalModal
} = partnerRelationSlice.actions;
export default partnerRelationSlice.reducer;
