import axios from 'axios';
import {useQuery} from 'react-query';

export const useNewsAndFeedbackConfig = () => {
  return useQuery(
    ['newsAndFeedbackConfig'],
    async () => {
      const response = await axios.get('/api/newsFeedback/config');
      return response.data;
    },
    // no feedback toast on error here.
    {onError: console.error}
  );
};
