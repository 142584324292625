import React, {useState} from 'react';
import {Button, Grid, Icon, Modal} from 'semantic-ui-react';
import {useDispatch} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {
  useAllCompanies,
  useMonitorConfig,
  useRootUserConfig
} from '@/hooks/monitorConfigHooks';
import MonitorModalContent from '../Styles/MonitorModalContent';
import MonitorModalActionButtonFooter from '../Styles/MonitorModalActionButtonFooter';
import {closePartnerRelationsMismatchModalModal} from '@/reducers/partnerRelations';
import {SelectedPartnerBoxLabel} from '../Styles/PartnerSelectionStyles';
import {changeSubCompany} from '@/reducers/userConfig';
import {ReactElementLike} from 'prop-types';
import {CompanyNodeDto} from '@/generated/monitor-api.schemas';

const CancelLink = styled.span`
  cursor: pointer;
  float: right;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-top: 21px;
  margin-right: 20px;
  color: #0054ff;
`;

const SelectedCompanyName = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 25px;
`;

interface Props {
  header: ReactElementLike;
  subHeader: ReactElementLike;
  switchLabel: ReactElementLike;
  switchToCompany: () => void;
  continueWithCurrentCompany: () => void;
  mismatchedCompany: CompanyNodeDto;
  switchButtonTranslation: ReactElementLike;
  type: string;
  loading: boolean;
}

const MismatchModalContent = ({
  header,
  subHeader,
  switchLabel,
  switchToCompany,
  continueWithCurrentCompany,
  mismatchedCompany,
  switchButtonTranslation,
  type,
  loading
}: Props) => (
  <MonitorModalContent
    onClose={continueWithCurrentCompany}
    header={header}
    subHeader={subHeader}>
    <Grid data-spec={`switch-type-${type}`}>
      <Grid.Row>
        <Grid.Column>
          <SelectedPartnerBoxLabel style={{marginTop: 19}}>
            {switchLabel}
          </SelectedPartnerBoxLabel>
          <SelectedCompanyName>{mismatchedCompany.name}</SelectedCompanyName>
        </Grid.Column>
      </Grid.Row>
      <MonitorModalActionButtonFooter>
        <Button
          loading={loading}
          disabled={loading}
          data-spec="switch-company-button"
          onClick={switchToCompany}
          style={{
            float: 'right',
            margin: 0,
            minWidth: 188,
            textAlign: 'left',
            height: 52
          }}>
          {switchButtonTranslation}
          <Icon name="chevron right" style={{float: 'right'}} />
        </Button>
        <CancelLink
          onClick={continueWithCurrentCompany}
          data-spec="cancel-switch">
          <FormattedMessage id="GENERAL_CANCEL" />
        </CancelLink>
      </MonitorModalActionButtonFooter>
    </Grid>
  </MonitorModalContent>
);

const SelectedCompanyMismatchModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const monitorConfig = useMonitorConfig();
  const allCompanies = useAllCompanies();
  const rootUserConfig = useRootUserConfig();
  const isOpen = monitorConfig.showPartnerRelationsMismatchModal;
  const [loading, setLoading] = useState(false);
  const mismatchUuid = monitorConfig.mismatchedSubCompanyUuid;

  if (!isOpen) {
    return null;
  }

  if (!mismatchUuid) {
    console.warn(
      `We should have shown the sub-company-mismatch modal, but no companyUuid was given.`
    );
    return null;
  }

  const mismatchedCompany = allCompanies.find(
    subCompany => subCompany.uuid === mismatchUuid
  );

  if (!mismatchedCompany?.name) {
    console.warn(
      `We should have shown the sub-company-mismatch modal for company ${mismatchUuid}, but could not find that company in the store.`,
      allCompanies
    );
    return null;
  }

  const switchToCompany = () => {
    setLoading(true);
    try {
      dispatch(
        changeSubCompany({
          companyUuid: mismatchedCompany.uuid
        })
      );

      dispatch(closePartnerRelationsMismatchModalModal());
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const continueWithCurrentCompany = () => {
    dispatch(closePartnerRelationsMismatchModalModal());
    history.push('');
  };

  // https://gitlab.ecosio.com/code/monitor/-/issues/230#note_172053
  if (rootUserConfig.isPartner) {
    return (
      <Modal open>
        <MismatchModalContent
          type="partner"
          loading={loading}
          mismatchedCompany={mismatchedCompany}
          switchToCompany={switchToCompany}
          continueWithCurrentCompany={continueWithCurrentCompany}
          switchButtonTranslation={<FormattedMessage id="SWITCH_PARTNER" />}
          switchLabel={<FormattedMessage id="CHANGE_PARTNER_TO" />}
          header={<FormattedMessage id="PARTNER_SWITCH_NEEDED" />}
          subHeader={<FormattedMessage id="PARTNER_SWITCH_DESC" />}
        />
      </Modal>
    );
  } else {
    return (
      <Modal open={isOpen}>
        <MismatchModalContent
          type="customer"
          loading={loading}
          mismatchedCompany={mismatchedCompany}
          switchToCompany={switchToCompany}
          continueWithCurrentCompany={continueWithCurrentCompany}
          switchButtonTranslation={<FormattedMessage id="SWITCH_COMPANY" />}
          switchLabel={<FormattedMessage id="CHANGE_COMPANY_TO" />}
          header={<FormattedMessage id="COMPANY_SWITCH_NEEDED" />}
          subHeader={<FormattedMessage id="COMPANY_SWITCH_DESC" />}
        />
      </Modal>
    );
  }
};

export default SelectedCompanyMismatchModal;
