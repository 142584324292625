import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {useAppSelector} from '@/hooks';
import {RootState} from '@/store';
import {KnownError} from '@/reducers/userConfig';
import {MonitorConfiguration} from '@/generated/monitor-api.schemas';

export const MONITOR_CONFIG_URL = '/api/config/monitor';

// See README.md for more info
export const SUB_COMPANY_PARAM = 'subCompany';

/**
 * @returns {{selectedSubCompanyName: string, selectedSubCompany: string, includeSubCompanies: boolean, selectedCompanies: Array<string>}}
 */
export const getSelectedCompany = (state: RootState) => {
  // TODO: i think it's only here because of tests, but it makes
  // typescript code unnecessarily complicated. we should fix the tests
  /*
  if (state.config.loading) {
    return {
      selectedSubCompany: undefined,
      selectedSubCompanyName: undefined,
      includeSubCompanies: undefined,
      selectedCompanies: undefined
    };
  }
   */

  const selectedSubCompany = state.config.userConfig.selectedSubCompany;
  const selectedSubCompanyName = state.config.userConfig.selectedSubCompanyName;
  const includeSubCompanies = state.config.userConfig.includeSubCompanies;
  const selectedCompanies = state.config.userConfig.selectedCompanies;
  return {
    selectedSubCompany,
    selectedSubCompanyName,
    includeSubCompanies,
    selectedCompanies
  };
};

export interface SelectedCompany {
  selectedSubCompany: string;
  selectedSubCompanyName: string;
  includeSubCompanies: boolean;
  selectedCompanies: string[];
}

/**
 * Hook to get the currently selected company.
 *
 * Can either be a selection from the SubCompanyDropdown (if the
 * users company has subCompanies), OR will be the company of the
 * currently logged in user if it has no subCompanies.
 *
 * If this method returns undefined, something is fishy.
 *
 * This is mainly used to trigger re-renders once the selected company changes
 * to fetch fresh data from the backend.
 *
 * TODO: move this hook to the hooks folder
 */
export const useSelectedCompany = (): SelectedCompany => {
  return useAppSelector(store => {
    return getSelectedCompany(store);
  });
};

export const useCompanyNode = () => {
  return useAppSelector(store => {
    return store.config.companyNode;
  });
};

export const fetchMonitorConfig = createAsyncThunk<
  MonitorConfiguration,
  string,
  {rejectValue: KnownError}
>('monitorConfig/fetch', async (companyUuid, thunkAPI) => {
  return axios
    .get(`${MONITOR_CONFIG_URL}/${companyUuid}`)
    .then(response => response.data)
    .catch(error => thunkAPI.rejectWithValue(error?.response || error));
});

export interface MonitorConfigState {
  loading: boolean;
  showPartnerRelationsMismatchModal: boolean;
  mismatchedSubCompanyUuid: string;
  data: MonitorConfiguration | null;
  error: string | null;
}

const initialState: MonitorConfigState = {
  loading: false,
  data: null,
  error: null,
  showPartnerRelationsMismatchModal: false,
  mismatchedSubCompanyUuid: ''
};

const slice = createSlice({
  name: 'monitorConfig',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchMonitorConfig.pending, state => {
        Object.assign(state, initialState);
        state.loading = true;
      })
      .addCase(fetchMonitorConfig.fulfilled, (state, action) => {
        // @ts-expect-error fixme
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchMonitorConfig.rejected, (state, action) => {
        Object.assign(state, initialState);
        state.loading = false;
        state.error = action.error as string;
        switch (action.payload?.status) {
          case 401:
          case 403:
            break;
          default:
            console.error(action.error);
        }
      });
  }
});

export default slice.reducer;
