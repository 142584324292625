import {reducer as locales} from '@ecosio/locales';
import download from './downloadReducer';
import monitorConfig from './monitorConfig';
import documentTypes from './documentTypes';
import config from './userConfig';
import partnerRelations from './partnerRelations';

export default () => ({
  // TODO: we should get rid of loading all messages in the redux store...
  locales,
  // contains configuration tied to the currently logged-in user
  config,
  // global download management
  download,
  // the monitor configuration of the currently selected company
  monitorConfig,
  // the available document types of the currently selected company
  // TODO: maybe merge with the monitorConfig reducer
  documentTypes,
  // mainly modal management for the partner relation feature
  partnerRelations
});
