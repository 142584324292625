import axios from 'axios';

export const DOWNLOAD_STATUS_FINISHED = 'FINISHED';
export const DOWNLOAD_STATUS_FAILED = 'FAILED';

export const pollDownloadId = downloadId => {
  return axios.get(`/api/messages/user-archive/${downloadId}/status`);
};

export const batchDownloadRequest = ({
  messageIds,
  selectAll,
  filter,
  senderOrReceiverUuid,
  includeAcknowledgment = false
}) => {
  // params are based if the user downloads by filter or by message id list
  let params;

  // user wants to download with filter
  if (selectAll && filter) {
    filter['senderOrReceiverUuid'] = senderOrReceiverUuid;
    filter['includeAcknowledgment'] = includeAcknowledgment;
    params = filter;
  } else {
    params = {
      messageIds,
      includeAcknowledgment,
      senderOrReceiverUuid
    };
  }

  const allParams = Object.assign(params, {selectAll});
  return axios.get(`/api/messages/download`, {params: allParams});
};
