import qs from 'qs';
import {dynID, isInternetExplorer, toast} from '@ecosio/components';
import logger from '../logger';

const debug = logger('utils');

export const isProd = () => {
  return import.meta.env.MODE === 'production';
};

/**
 * When stringifying query params to push into the history, these
 * global parameters will be ignored
 * @type {string[]}
 */
const IGNORED_QUERY_PARAMS = [
  /* ignored because in the Dashboard we pass this around as a backend parameter, which should not be exposed to the user URL */
  'timeZone'
];

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!!!!!! IMPORTANT !!!!!!!!!!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * Note: please do not use this function to stringify query params
 * for an axios request, rather pass the parameters of the request
 * in the axios config object.
 *
 * If you use this build an axios URL with GET parameters, this WILL BREAK
 * ELENA BACKEND CALLS.
 *
 * Only use this to generate links or push to the history
 */
export const stringifyQuery = params => {
  const newParams = Object.assign({}, params);
  IGNORED_QUERY_PARAMS.forEach(param => delete newParams[param]);
  return qs.stringify(newParams, {indices: false});
};

export const DIRECTION_CONVERTER_OUT = {
  ascending: 'asc',
  descending: 'desc'
};

export const DIRECTION_CONVERTER_IN = {
  asc: 'ascending',
  desc: 'descending'
};

export const translateOptions = (intl, options) =>
  options.map(option => ({
    ...option,
    text: intl.formatMessage({id: option.text})
  }));

export const doRedirectWhenIE = location => {
  // anything below '/monitor/masterdata' is permitted for IE11
  const ALLOWED_PATHS = ['/masterdata'];

  if (isInternetExplorer()) {
    const currentLocation = location.pathname;
    const isAllowed = ALLOWED_PATHS.some(prefix =>
      currentLocation.startsWith(prefix)
    );
    if (!isAllowed) {
      const host = isProd() ? '' : 'http://127.0.0.1:9944';
      // redirect to the account settings page instead of the dashboard because
      // the dashboard has logic to redirect to monitor, if it's the only app that's
      // available. This prevents redirect loops when the `warning` parameter isn't
      // passed along correctly.
      const url = `${host}/account/settings/account?warning=IE11_NEW_MONITOR_WARNING`;
      debug('doRedirectWhenIE redirecting to', url);
      // window.location.href = url;
    }
  }
};

/**
 *
 * @param history
 * @param location
 * @param params an array with key/values params to replace
 */
export const setParametersInUrl = (history, location, params) => {
  if (!Array.isArray(params)) {
    debug(`Not replacing params, received wrong param type ${params}`);
    return;
  }

  const existingQuery = qs.parse(location.search, {ignoreQueryPrefix: true});

  params
    .filter(param => {
      return typeof param.key === 'string' && typeof param.value === 'string';
    })
    .forEach(param => {
      existingQuery[param.key] = param.value;
    });

  const newQueryString = stringifyQuery(existingQuery);
  debug(
    `Replacing search in '${location.search}' location string with '${newQueryString}'`
  );
  history.push({search: `?${newQueryString}`});
};

/**
 *
 * @param history
 * @param location
 * @param paramsToDelete an array with keys (params names) to delete
 */
export const deleteParametersInUrl = (history, location, paramsToDelete) => {
  if (!Array.isArray(paramsToDelete)) {
    debug(`Not replacing params, received wrong param type ${paramsToDelete}`);
    return;
  }

  const newQuery = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });

  paramsToDelete.forEach(param => {
    if (newQuery[param]) {
      delete newQuery[param];
    }
  });
  history.push({search: `?${stringifyQuery(newQuery)}`});
};

/**
 * Prepare a data attribute for the tests.
 * @param {*} attributeValue
 * @return {string} sanitized data attribute
 */
export const getDataAttribute = attributeValue => {
  const query = attributeValue || '';
  // allow searching with double quotes and still have a valid data attribute

  if (typeof query?.replaceAll === 'function') {
    return query.replaceAll('"', "'");
  }
  return '';
};

export const handleSuccess = (title, description, intl) => {
  toast({
    type: 'success',
    title: intl.formatMessage({id: dynID(title)}),
    description: intl.formatMessage({id: dynID(description)}),
    time: 5000
  });
};

export const handleError = (e, intl) => {
  console.error(e);
  toast({
    type: 'error',
    title: intl.formatMessage({id: 'GENERAL_ERROR'}),
    description: e.message
  });
};
