import {
  determineEnvironmentFromLocation,
  getUrlForEnvironment
} from '@ecosio/auth';

export const getMonitorBaseUrl = () => {
  const env = determineEnvironmentFromLocation(window.location.href);
  return getUrlForEnvironment(env, 'monitor');
};

export const getAccountsBaseUrl = () => {
  const env = determineEnvironmentFromLocation(window.location.href);
  return getUrlForEnvironment(env, 'accounts');
};
