import React, {Fragment, useCallback, useEffect} from 'react';
import {Icon, Message} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from '@ecosio/components';
import {Link} from 'react-router-dom';
import logger from '../../logger';
import {
  DOWNLOAD_STATUS_FAILED,
  DOWNLOAD_STATUS_FINISHED,
  pollDownloadId
} from '../Modals/downloadApi';
import {CONTEXT_PATH} from '../../constants';
import {
  downloadSuccess,
  downloadError,
  closeDownload
} from '../../reducers/downloadReducer';
import {triggerFileDownload} from '@/helpers/download';

const debug = logger('download-progress');
const POLL_INTERVAL = 1000; // poll every second

/**
 * TODO: cancel download button
 */
const DownloadProgressIndicator = () => {
  const downloadPending = useSelector(state => state.download?.downloadPending);
  const downloadDone = useSelector(state => state.download?.downloadDone);
  const downloadId = useSelector(state => state.download?.downloadId);
  const lastDownloadId = useSelector(state => state.download?.lastDownloadId);

  const dispatch = useDispatch();
  const shouldPoll = downloadPending && downloadId;

  /**
   * If the redux store has information that the user started a download, we have to keep polling for that id
   * TODO: jest-test
   */
  const pollData = useCallback(() => {
    if (!shouldPoll) {
      return;
    }

    debug(`Polling for download id ${downloadId}`);
    pollDownloadId(downloadId)
      .then(res => {
        if (!res) {
          console.warn("Status couldn't be retrieved");
          return;
        }

        const state = res.data;
        if (state === DOWNLOAD_STATUS_FINISHED) {
          dispatch(downloadSuccess());
        } else if (state === DOWNLOAD_STATUS_FAILED) {
          // error message and remove redux info
          toast({
            title: (
              <b>
                <FormattedMessage id="GENERAL_DOWNLOAD_FAILED" />
                <br />
              </b>
            ),
            type: 'error',
            time: 5000
          });
          dispatch(downloadError());
          console.error(
            `Error polling download, downloadId (archiveId) = ${downloadId}`
          );
        }
      })
      .catch(err => {
        toast({
          title: (
            <b>
              <FormattedMessage id="GENERAL_DOWNLOAD_FAILED" />
              <br />
            </b>
          ),
          type: 'error',
          time: 5000
        });
        console.error(err);
      });
  }, [dispatch, downloadId, shouldPoll]);

  useEffect(() => {
    if (downloadPending) {
      const interval = setInterval(pollData, POLL_INTERVAL);
      return () => clearInterval(interval);
    }
  }, [downloadPending, pollData]);

  const downloadFinished =
    !downloadPending &&
    downloadDone &&
    typeof downloadId !== 'undefined' &&
    downloadId !== null;

  if (downloadFinished) {
    const url = `${CONTEXT_PATH}/api/messages/user-archive/${downloadId}`;
    debug(`Opening download url at ${url}`);
    dispatch(closeDownload());
    // Do NOT use window.open(), this will trigger popup blockers
    triggerFileDownload(url);
    return <div data-spec="last-download-id" data-downloadid={downloadId} />;
  }

  if (downloadPending) {
    return (
      <div data-spec="download-pending">
        <Message
          icon
          size="small"
          floating
          style={{
            width: '400px',
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: '1000'
          }}>
          <Icon color="blue" name="circle notched" loading />
          <Message.Content style={{display: 'flex', marginRight: 0}}>
            <div style={{flex: 6}}>
              <Message.Header>
                <FormattedMessage id="MONITOR_DOWNLOAD_PREPARING" />
              </Message.Header>
              <FormattedMessage id="MONITOR_DOWNLOAD_COMPRESSING_FILES" />
            </div>
            <div style={{flex: 2, textAlign: 'right', paddingTop: 7}}>
              <Link to="/user-archives">
                <Icon color="blue" name="chevron right" />
              </Link>
            </div>
          </Message.Content>
        </Message>
      </div>
    );
  }

  // we need to track the last download id somewhere in the dom for e2e tests...
  if (lastDownloadId) {
    return (
      <div data-spec="last-download-id" data-downloadid={lastDownloadId} />
    );
  } else {
    return <Fragment />;
  }
};

export default DownloadProgressIndicator;
