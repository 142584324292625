import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {FormattedMessage as Msg} from 'react-intl';
import {Icon} from 'semantic-ui-react';
import {debugSubCompany} from '@/logger';
import {useSelectedCompany} from '@/reducers/monitorConfig';
import {getConnectorCount} from '@/pages/Settings/settingsApi';
import {useMonitorConfig, useUserConfig} from '@/hooks/monitorConfigHooks';
import {
  EDI_RELATIONS_URL,
  ONBOARDING_TICKETS_URL,
  SUPPORT_TICKETS_URL
} from '@/AppRouter';
import {trackEvent} from '@/helpers/analytics';

const debugCompany = debugSubCompany('sidebar-items');

const ANALYTICS_MAIN_CATEGORY = 'Button Click';
const ANALYTICS_EVENT_KEY = 'Left-hand meu';

const trackMenuClick = (entry, userConfig) => {
  trackEvent(ANALYTICS_EVENT_KEY, {
    mainCategory: ANALYTICS_MAIN_CATEGORY,
    subCategory: entry,
    item: ANALYTICS_EVENT_KEY,
    companyName: userConfig?.company?.name,
    companyUuid: userConfig?.company?.uuid
  });
};

export const USER_ARCHIVE_ICON = 'folder open';

export const ConnectorsNavLink = () => {
  const userConfig = useUserConfig();
  const {selectedSubCompany: selectedCompany, includeSubCompanies} =
    useSelectedCompany();
  const [connectorCount, setConnectorCount] = useState(0);

  useEffect(() => {
    debugCompany(`Loading connector count with subCompany ${selectedCompany}`);
    getConnectorCount()
      .then(result => {
        try {
          debugCompany(`Got connector count result`, result);
          const count = parseInt(result.data);
          if (!isNaN(count)) {
            debugCompany(`Setting connector count to ${count}`);
            setConnectorCount(count);
          } else {
            debugCompany(
              `Could not parse connector count from result`,
              result.data
            );
          }
        } catch (error) {
          debugCompany(`response parsing error: ${error.message}`);
          console.error(error);
          setConnectorCount(0);
        }
      })
      .catch(error => {
        debugCompany(`http error loading connector count: ${error.message}`);
        console.error(error);
        setConnectorCount(0);
      });
  }, [selectedCompany, includeSubCompanies]);

  if (!connectorCount) {
    debugSubCompany(
      `Not rendering connectors sidebar element, connectorCount is ${connectorCount}`
    );
    return null;
  }

  debugSubCompany(
    `Rendering connectors sidebar element, connectorCount is ${connectorCount}`
  );
  return (
    <NavLink
      to={{pathname: '/connectors'}}
      data-spec="connectors-navlink"
      onClick={() => trackMenuClick('Connectors', userConfig)}>
      <div className="icon">
        <Icon name="expand arrows alternate" />
      </div>
      <Msg id="SIDEBAR_CONNECTORS" />
    </NavLink>
  );
};

export const PartnerMatrixNavLink = () => {
  const config = useMonitorConfig();
  const userConfig = useUserConfig();

  if (config?.data?.partnerMatrixEnabled) {
    return (
      <NavLink
        to={ONBOARDING_TICKETS_URL}
        data-spec="partner-matrix-navlink"
        onClick={() => trackMenuClick('Onboardings', userConfig)}>
        <div className="icon">
          <Icon
            name="handshake outline"
            style={{padding: '0', margin: '0 .25rem 0 0'}}
          />
        </div>
        <Msg id="SIDEBAR_PARTNER_MATRIX" />
      </NavLink>
    );
  } else {
    return null;
  }
};

export const SupportTicketsNavLink = () => {
  const config = useMonitorConfig();
  const userConfig = useUserConfig();

  if (config?.data?.supportTicketPageEnabled) {
    return (
      <NavLink
        to={SUPPORT_TICKETS_URL}
        data-spec="support-tickets-navlink"
        onClick={() => trackMenuClick('Support tickets', userConfig)}>
        <div className="icon">
          <Icon
            name="clipboard outline"
            style={{padding: '0', margin: '0 .25rem 0 0'}}
          />
        </div>
        <Msg id="SIDEBAR_SUPPORT_TICKETS" />
      </NavLink>
    );
  } else {
    return null;
  }
};

export const EDIRelationsNavLink = () => {
  const userConfig = useUserConfig();
  const monitorConfig = useMonitorConfig();

  if (
    !(
      userConfig?.ediRelationsEnabled &&
      monitorConfig?.data?.ediRelationsPageEnabled
    )
  ) {
    return null;
  }

  return (
    <NavLink
      to={EDI_RELATIONS_URL}
      data-spec="edi-relations-navlink"
      onClick={() => trackMenuClick('EDI Relations', userConfig)}>
      <div className="icon">
        <Icon name="chart bar outline" style={{padding: '0'}} />
      </div>
      <Msg id="SIDEBAR_EDI_RELATIONS" />
    </NavLink>
  );
};

export const sidebarMenuItems = userConfig => [
  {
    key: 'dashboard',
    idx: 0,
    translation: <Msg id="SIDEBAR_DASHBOARD_ITEM" />,
    link: (
      <NavLink
        to="/"
        exact
        data-spec="dashboard-navlink"
        onClick={() => trackMenuClick('Dashboard', userConfig)}>
        <div className="icon">
          <Icon name="chart bar outline" />
        </div>
        <Msg id="SIDEBAR_DASHBOARD_ITEM" />
      </NavLink>
    )
  },
  {
    key: 'messages',
    idx: 1,
    translation: <Msg id="SIDEBAR_MESSAGES_ITEM" />,
    link: (
      <NavLink
        to={{
          pathname: '/messages',
          search: '?sortField=lastStatusChange&sortOrder=desc'
        }}
        onClick={() => trackMenuClick('Messages', userConfig)}
        data-spec="messages-navlink">
        <div className="icon">
          <Icon name="envelope" />
        </div>
        <Msg id="SIDEBAR_MESSAGES_ITEM" />
      </NavLink>
    )
  },
  {
    key: 'user-archives',
    idx: 2,
    translation: <Msg id="SIDEBAR_USER_ARCHIVES_ITEM" />,
    link: (
      <NavLink
        to={{
          pathname: '/user-archives',
          search: '?sort=timestamp,desc'
        }}
        onClick={() => trackMenuClick('Downloads', userConfig)}
        data-spec="user-archives-navlink">
        <div className="icon">
          <Icon name={USER_ARCHIVE_ICON} />
        </div>
        <Msg id="SIDEBAR_USER_ARCHIVES_ITEM" />
      </NavLink>
    )
  },
  {
    key: 'statistics',
    idx: 4,
    translation: <Msg id="SIDEBAR_STATISTICS_PAGE" />,
    link: (
      <NavLink
        to="/statistics"
        onClick={() => trackMenuClick('Statistics', userConfig)}
        data-spec="stats-navlink">
        <div className="icon">
          <Icon
            name="list alternate outline"
            style={{padding: '0', margin: '0 .25rem 0 0'}}
          />
        </div>
        <Msg id="SIDEBAR_STATISTICS_ITEM" />
      </NavLink>
    )
  },
  {
    key: 'connectors',
    idx: 3,
    translation: <Msg id="SIDEBAR_CONNECTORS" />,
    link: <ConnectorsNavLink />
  },
  {
    key: 'partner-matrix',
    idx: 5,
    translation: <Msg id="SIDEBAR_PARTNER_MATRIX" />,
    link: <PartnerMatrixNavLink />,
    requiredAuthority: 'ELENA_TICKET_VIEW'
  },
  {
    key: 'support-tickets',
    idx: 6,
    translation: <Msg id="SIDEBAR_SUPPORT_TICKETS" />,
    link: <SupportTicketsNavLink />,
    requiredAuthority: 'ELENA_TICKET_VIEW'
  },
  {
    key: 'edi-relations',
    idx: 7,
    translation: <Msg id="SIDEBAR_EDI_RELATIONS" />,
    link: <EDIRelationsNavLink />
  }
];
