import {createBrowserHistory} from 'history';
import {configureStore} from '@reduxjs/toolkit';
import createRootReducer from './reducers';
import {doRedirectWhenIE} from './helpers/utils';
import {CONTEXT_PATH} from './constants';
import createConfigListenerMiddleware from './listenerMiddleware';

export const history = createBrowserHistory({basename: CONTEXT_PATH});

history.listen(location => {
  doRedirectWhenIE(location);
});

const isCi = typeof process !== 'undefined' && process.env?.CI;

const store = configureStore({
  reducer: createRootReducer(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: true,
      // incompatible with axios errors in slices...
      // https://stackoverflow.com/a/66759645/539144
      serializableCheck: false,
      // our ci instances running the tests are pretty slow it seems
      // locally we want to have a smaller threshold
      immutableCheck: {warnAfter: isCi ? 300 : 50}
    }).prepend(createConfigListenerMiddleware(history))
  //      .concat(extraMiddleware)
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
