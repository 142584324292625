import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import logger from '../logger';

const log = logger('document-types');

export const initialState = {
  loading: true,
  error: null,
  data: null
};

export const fetchDocumentTypes = createAsyncThunk(
  'documentTypes/fetch',
  async () => {
    log('Loading document types...');
    const url = '/api/statistics/document-type-classifications';
    const response = await axios.get(url);
    log(`Document Types loaded: ${JSON.stringify(response?.data)}`);
    return response.data;
  }
);

const slice = createSlice({
  name: 'documentTypes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDocumentTypes.pending, state => {
        state.loading = true;
      })
      .addCase(fetchDocumentTypes.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchDocumentTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  }
});

export const useDocumentTypes = () => {
  const data = useSelector(state => state.documentTypes?.data);
  return data || [];
};

export const useDocumentTypeOptions = () => {
  const documentTypes = useDocumentTypes();
  return useMemo(() => {
    return documentTypes.map(type => ({
      text: type,
      value: type,
      key: type
    }));
  }, [documentTypes]);
};

export default slice.reducer;
