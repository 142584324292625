import {createListenerMiddleware} from '@reduxjs/toolkit';
import axios from 'axios';
import {changeSubCompany, fetchUserConfig} from './reducers/userConfig';
import {fetchMonitorConfig} from './reducers/monitorConfig';
import {fetchDocumentTypes} from './reducers/documentTypes';
import {setParametersInUrl} from './helpers/utils';
import {getAccountsBaseUrl} from './helpers/urls';

const handleAuthRedirect = () => {
  const currentUrl = /\/logout/.test(window.location.href)
    ? window.location.origin
    : window.location.href;

  const returnTo = `?returnTo=${encodeURIComponent(currentUrl)}`;
  const redirectTo = `${getAccountsBaseUrl()}/login${returnTo}`;

  window.location.assign(redirectTo);
};

const createConfigListenerMiddleware = history => {
  const listenerMiddleware = createListenerMiddleware();

  listenerMiddleware.startListening({
    actionCreator: fetchUserConfig.rejected,
    effect: async action => {
      if (action.payload?.status === 401) {
        handleAuthRedirect();
      }

      if (action.payload?.status === 403) {
        console.warn(
          'Got a 403 from a monitor/config/user backend request. This should not happen, redirecting to login'
        );
        handleAuthRedirect();
      }
    }
  });

  listenerMiddleware.startListening({
    actionCreator: changeSubCompany,
    effect: async (action, listenerApi) => {
      const {companyUuid} = action.payload;

      const state = listenerApi.getState();
      const selectedCompanies = state.config.userConfig.selectedCompanies;

      // We change the URL because some list views rely on the URL
      // change to reload data from the backend
      setParametersInUrl(history, window.location, [
        {key: 'subCompany', value: selectedCompanies.join(',')}
      ]);

      listenerApi.dispatch(fetchMonitorConfig(companyUuid));
      listenerApi.dispatch(fetchDocumentTypes());
      try {
        await axios.post(`/api/user/select-sub-company`, {
          selectedSubCompany: companyUuid
        });
      } catch (error) {
        console.error(error);
      }
    }
  });

  return listenerMiddleware.middleware;
};
export default createConfigListenerMiddleware;
