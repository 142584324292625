import debug from 'debug';

const padString = value => {
  const maxLength = 40;
  try {
    return value.length > maxLength
      ? `${value.substring(0, maxLength - 3)}...`
      : value.padEnd(maxLength);
  } catch (e) {
    console.warn(e);
    return value;
  }
};

const defaultLogger = name => {
  const loggerName = `monitor:${name}`;
  return debug(padString(loggerName));
};

/**
 * logger for debugging anything related to sub-company handling
 */
export const debugSubCompany = name => {
  return defaultLogger(`company-select:${name}`);
};

export default defaultLogger;
