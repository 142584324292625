import Countly from 'countly-sdk-web';
import {APP_ENV, determineEnvironmentFromLocation} from '@ecosio/components';
import {UserConfig} from '@/reducers/userConfig';

const app = 'monitor';
let disabled = true;

const IGNORED_EMAILS = ['@mailtest.ecosio.com', '@simulator.amazones.com'];

export const MESSAGE_DIRECTION_TYPE: Record<string, string> = {
  searchType: 'Message direction'
};

export const MESSAGE_STATUS_VALUES: Record<string, string> = {
  SENT: 'Sent',
  ALL: 'All',
  EXCEPTION: 'Exception',
  CUSTOMER_EXCEPTION: 'Exception (customer)'
};

export const MESSAGE_DIRECTION_VALUES: Record<string, string> = {
  ALL: 'Sent/Received',
  RECEIVED: 'Received',
  SENT: 'Sent'
};

// https://gitlab.ecosio.com/code/monitor/-/issues/1148
export enum MainCategory {
  ElementClick = 'Element click',
  ButtonClick = 'Button click',
  FilterClick = 'Filter click',
  InputField = 'Input field'
}

export interface AnalyticsSegmentation {
  mainCategory: MainCategory;
  companyUuid: string;
  companyName: string;
  // the optional thing that has been selected or text that has been entered
  item?: string;
  // an optional sub-category, e.g. the name of a form field or element
  // that has been clicked
  subCategory?: string;
}

const getRevision = (): string => {
  try {
    // @ts-expect-error TBD
    return import.meta.env.VITE_REVISION;
  } catch (error) {
    console.error(error);
    return 'Unknown';
  }
};

export const setupSdk = (userConfig: UserConfig): boolean => {
  try {
    if (
      typeof IGNORED_EMAILS.find(
        ignored => userConfig.email.indexOf(ignored) > -1
      ) !== 'undefined'
    ) {
      // don't track e2e test users
      console.info(`Countly disabled, user ${userConfig.email} is ignored`);
      return false;
    }

    const env = determineEnvironmentFromLocation(window.location.href);

    if (env === APP_ENV.DEV) {
      return false;
    }

    disabled = false;

    // eslint-disable-next-line camelcase
    let app_key = '4f377f3b468ef5b82ed722cbb5a1d418afc0fd85';
    let url = 'https://ecosio-27eebab1ffca9.flex.countly.com';

    switch (env) {
      case APP_ENV.PROD:
        // eslint-disable-next-line camelcase
        app_key = '9f0140a8c5ff3ca6038def0b4e0b5aa5129e50ff';
        url = 'https://ecosio-c7ab9d997f488.flex.countly.com';
        break;
      case APP_ENV.TEST:
        // eslint-disable-next-line camelcase
        app_key = '06c44e17ab27e0f1eb961aa4e589b280de265999';
        url = 'https://ecosio-c7ab9d997f488.flex.countly.com';
        break;
      case APP_ENV.DEV:
        //       case APP_ENV.DOCKER:
        // eslint-disable-next-line camelcase
        app_key = '4f377f3b468ef5b82ed722cbb5a1d418afc0fd85';
        url = 'https://ecosio-27eebab1ffca9.flex.countly.com';
        break;
    }

    // https://support.countly.com/hc/en-us/articles/360037441932-Web-analytics-JavaScript
    Countly.init({
      // eslint-disable-next-line camelcase
      app_key,
      url,
      debug: false,
      // eslint-disable-next-line camelcase
      device_id: userConfig.email,
      // eslint-disable-next-line camelcase
      clear_stored_id: true
    });
    Countly.track_errors({version: getRevision()});
    Countly.track_sessions();
    trackUserDetails(userConfig);

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const trackUserDetails = (userConfig: UserConfig): void => {
  if (disabled) {
    return;
  }

  try {
    Countly.q.push([
      'user_details',
      {
        name: `${userConfig.firstName} ${userConfig.lastName}`,
        username: userConfig.email,
        email: userConfig.email,
        organization: userConfig.company.name,
        custom: {
          selectedSubCompanyName: userConfig.selectedSubCompanyName,
          includeSubCompanies: userConfig.includeSubCompanies
        }
      }
    ]);
  } catch (error) {
    console.error(error);
  }
};

export const trackPageView = (
  viewName: string,
  companyUuid: string,
  companyName: string
) => {
  if (disabled) {
    return;
  }

  // https://support.count.ly/hc/en-us/articles/360037441932-Web-analytics-JavaScript#h_01HABTQ437CAD08ESRK6RMJ2FG
  try {
    Countly.q.push(['track_pageview', `${viewName}`], null, {
      segmentation: {
        env: determineEnvironmentFromLocation(window?.location?.href),
        companyUuid,
        companyName,
        app
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export const trackEvent = (
  name: string,
  segmentation: AnalyticsSegmentation
): void => {
  if (disabled) {
    return;
  }

  try {
    const {companyUuid, companyName, ...rest} = segmentation;
    Countly.q.push([
      'add_event',
      {
        key: name,
        count: 1,
        segmentation: Object.assign(
          {
            // nameFormat: segmentation.nameFormat,
            env: determineEnvironmentFromLocation(window?.location?.href),
            companyUuid,
            companyName,
            app
          },
          rest
        )
      }
    ]);
  } catch (error) {
    console.error(error);
  }
};
